export enum IndexAnchor {
  DirectIndexCalculator = "direct-index-calculator",
  YourMoneyIsSecure = "your-money-is-secure",
}
export enum FootnoteAnchor {
  TwoXMoreDisclaimer = "two-x-more-disclaimer",
  LowerFeesDisclaimerSPY = "lower-fees-disclaimer-spy",
  TreasuryDisclaimer = "treasury-disclaimer",
  ApexDisclaimer = "apex-clearing-assets-disclaimer",
  DITaxAlphaDisclaimer = "di-tax-alpha-disclaimer",
  LowerFeesDisclaimer = "lower-fees-disclaimer",
  ETFExpenseRatioDisclaimer = "etf-expense-ratio-disclaimer",
  VariableLTVDisclaimer = "variable-ltv-disclaimer",
  CapGainTaxRateDisclaimer = "cap-gain-tax-rate-disclaimer",
  HypotheticalDataDisclaimer = "hypothetical-data-disclaimer",
  DIResultsDisclaimer = "di-results-disclaimer",
  DICustomizationDisclaimer = "di-customization-disclaimer",
  LowFeesLowMinimumsDisclaimer = "low-fees-low-minimums-disclaimer",
  FortyPercentFootnote = "forty-percent-footnote",
  FortyPercentDisclaimer = "forty-percent-disclaimer",
  AffiliateDisclaimer = "affiliate-disclaimer",
  MarginDisclaimer = "margin-disclaimer",
  TrademarkDisclaimer = "trademark-disclaimer",
}
export const borrowRatesAnchor = "borrow-rates";

export const IndexFootnotes = {
  withTwoXMoreDisclaimer: 1,
  withLowerFeesDisclaimerSPY: 2,
  withTreasuryDisclaimer: 3,
  withApexDisclaimer: 4,
};

export const PricingFootnotes = {
  withDITaxAlphaDisclaimer: 1,
  withLowerFeesDisclaimer: 2,
  withETFExpenseRatioDisclaimer: 3,
  withVariableLTVDisclaimer: 4,
};

export const TLHFootnotes = {
  withCapGainTaxRateDisclaimer: 1,
  withFortyPercentFootnote: 2,
};

export const GetStartedFootnotes = {
  withDIResultsDisclaimer: 1,
  withDICustomizationDisclaimer: 2,
  withLowFeesLowMinimumsDisclaimer: 3,
  withMarginDisclaimer: 4,
  withTreasuryDisclaimer: 5,
};

export const exploreIndicesAnchor = "explore-indices";
