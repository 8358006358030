import { UilAngleDown, UilAngleRight } from "@iconscout/react-unicons";
import { Collapse } from "@mantine/core";
import {
  FC,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react";

import { classNames } from "@frec-js/common-web";

type ExpandableProps = {
  head: ReactNode;
  className?: string;
  iconClassName?: string;
  contentClassName?: string;
};
export const Expandable: FC<PropsWithChildren<ExpandableProps>> = ({
  head,
  className,
  children,
  iconClassName = "",
  contentClassName = "",
}) => {
  const [opened, setOpen] = useState(false);

  return (
    <div className={className ?? ""}>
      <div
        className={classNames(
          "hover:text-frecBlack flex cursor-pointer items-center",
          opened ? "text-frecBlack" : "",
        )}
        onClick={() => setOpen((o) => !o)}
      >
        {opened ? (
          <UilAngleDown
            width={24}
            height={24}
            className={classNames("mr-2 h-4 w-4 shrink-0", iconClassName)}
          />
        ) : (
          <UilAngleRight
            width={24}
            height={24}
            className={classNames("mr-2 h-4 w-4 shrink-0", iconClassName)}
          />
        )}
        {isValidElement(head) ? head : <span>{head}</span>}
      </div>
      <Collapse
        in={opened}
        className={classNames("py-2 pl-6", contentClassName)}
      >
        {children}
      </Collapse>
    </div>
  );
};
